import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentExhibitionId, setCurrentExhibitionId] = useState(null);
  
  // Load data for the current exhibition on initial render
  useEffect(() => {
    // This will be called when exhibition changes or on initial render
    if (currentExhibitionId) {
      const storedUserData = localStorage.getItem(`userData_${currentExhibitionId}`);
      if (storedUserData) {
        try {
          const parsedData = JSON.parse(storedUserData);
          setCurrentUser(parsedData.user);
        } catch (error) {
          console.error('Error parsing user data:', error);
          // Clear invalid data
          localStorage.removeItem(`userData_${currentExhibitionId}`);
        }
      }
    }
  }, [currentExhibitionId]);

  // Login - store exhibition-specific data
  const login = (token, id,name, exhibitionId) => {
    const user = {id,name}
    if (!exhibitionId) {
      console.error('No exhibition ID provided for login');
      return false;
    }
    
    // Create the userData object
    const userData = {
      token,
      user,
      loggedInAt: new Date().toISOString()
    };
    console.log(userData)
    // Store user data with exhibition-specific key
    localStorage.setItem(`userData_${exhibitionId}`, JSON.stringify(userData));
    
    // Set current user and exhibition ID
    setCurrentUser(user);
    setCurrentExhibitionId(exhibitionId);
    
    return true;
  };

  // Check if user is logged into a specific exhibition
  const isLoggedInToExhibition = (exhibitionId) => {
    if (!exhibitionId) return false;
    
    const userData = localStorage.getItem(`userData_${exhibitionId}`);
    return !!userData;
  };

  // Get user data for a specific exhibition
  const getUserDataForExhibition = (exhibitionId) => {
    if (!exhibitionId) return null;
    
    try {
      const userData = localStorage.getItem(`userData_${exhibitionId}`);
      return userData ? JSON.parse(userData) : null;
    } catch (error) {
      console.error('Error getting user data for exhibition:', error);
      return null;
    }
  };
  
  // Change active exhibition (switch context to another exhibition)
  const switchExhibition = (exhibitionId) => {
    if (isLoggedInToExhibition(exhibitionId)) {
      setCurrentExhibitionId(exhibitionId);
      const userData = getUserDataForExhibition(exhibitionId);
      setCurrentUser(userData?.user || null);
      return true;
    }
    return false;
  };

  // Logout from a specific exhibition
  const logoutFromExhibition = (exhibitionId) => {
    if (!exhibitionId) return;
    
    localStorage.removeItem(`userData_${exhibitionId}`);
    
    // If logged out from current exhibition, clear current user
    if (exhibitionId === currentExhibitionId) {
      setCurrentUser(null);
      setCurrentExhibitionId(null);
    }
  };

  // Logout from all exhibitions
  const logoutFromAllExhibitions = () => {
    // Find all exhibition-specific keys
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('userData_')) {
        keys.push(key);
      }
    }
    
    // Remove all exhibition data
    keys.forEach(key => localStorage.removeItem(key));
    
    // Reset state
    setCurrentUser(null);
    setCurrentExhibitionId(null);
  };

  const value = {
    currentUser,
    currentExhibitionId,
    login,
    isLoggedInToExhibition,
    getUserDataForExhibition,
    switchExhibition,
    logoutFromExhibition,
    logoutFromAllExhibitions
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};