import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Exhibitors from './pages/Exhibitors';
import Exhibits from './pages/Exhibits';
import Pavilions from './pages/Pavilions';
import QRScanner from './pages/QRScanner';
import AppNavbar from './components/Navbar';
import { ExhibitionProvider } from './context/ExhibitionContext';
import { LanguageProvider } from './context/LanguageContext';
import ExhibitorDetail from './pages/ExhibitorDetail';
import ExhibititAssociatedExhibitors from './pages/ExhibitAssosiatedExhibitors';
import PavilionExhibitList from './pages/PavilionExhibitList';
import MapScreen from './pages/MapScreen';
import Login from './pages/Login';

function AppContent() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Service Worker registration
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./service-worker.js')
        .then(() => console.log('Service Worker registered'))
        .catch(error => console.log('Service Worker registration failed:', error));
    }

    // PWA install prompt listener
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      console.log('Install prompt saved');
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  if (isLoading) {
    return <div className="loading-container"><div className="loading-spinner"></div></div>;
  }

  return (
    <>
      <style jsx>{`
        .install-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          background-color: var(--accent-color, #0ea5e9);
          color: var(--text-color, #f8fafc);
          border: none;
          border-radius: var(--border-radius, 16px);
          padding: 12px 16px;
          font-size: 1rem;
          font-weight: 600;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          transition: background-color 0.2s, transform 0.2s;
          z-index: 1000;
          font-family: 'SF Pro Display', system-ui, sans-serif;
        }
        
        .install-button:hover {
          background-color: var(--primary-color, #0284c7);
          transform: translateY(-2px);
        }
        
        .loading-container {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .loading-spinner {
          width: 40px;
          height: 40px;
          border: 3px solid rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          border-top-color: var(--accent-color, #0ea5e9);
          animation: spin 1s ease-in-out infinite;
        }
        
        @keyframes spin {
          to { transform: rotate(360deg); }
        }
      `}</style>
      
      <div className="app-content">
        <AppNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/exhibitors" element={<Exhibitors />} />
          <Route path="/exhibitors/:id" element={<ExhibitorDetail />} />
          <Route path="/exhibits" element={<Exhibits />} />
          <Route path="/exhibit-associated-exhibitors/:id" element={<ExhibititAssociatedExhibitors />} />
          <Route path="/pavilions" element={<Pavilions />} />
          <Route path="/pavilion-exhibit-list/:pavilionId" element={<PavilionExhibitList />} />
          <Route path="/qr-scanner" element={<QRScanner />} />
          <Route path="/map" element={<MapScreen />} />
          <Route path="/exhibitor-login" element={<Login />} />
        </Routes>
        
        {deferredPrompt && (
          <button 
            onClick={handleInstallClick} 
            className="install-button"
          >
            Install App
          </button>
        )}
      </div>
    </>
  );
}

function App() {
  return (
    <LanguageProvider>
      <ExhibitionProvider>
        <Router>
          <AppContent />
        </Router>
      </ExhibitionProvider>
    </LanguageProvider>
  );
}

export default App;