import axios from 'axios';

const uid ='b0a10bc92d7ae616624e1f54b3518c2f'
export const fetchExhibitions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GETEXHIBITION}/${uid}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
        },
      });
  
      if (response.data && response.data.results) {
        // Return the transformed exhibitions array
        return response.data.results.map((exhibition) => ({
          value: exhibition.ExCode,
          label: exhibition.Title,
          labelEng: exhibition.TitleEng,
          Descr: exhibition.Descr,
          DescrEng: exhibition.DescrEng,
          bannerUrl: exhibition.Logo,
          VATNeeded: exhibition.VATNeeded,
          CompanyActivitiesListNeeded: exhibition.CompanyActivitiesListNeeded,
          JobListNeeded: exhibition.JobListNeeded,
          RegistrationMsgGr: exhibition.RegistrationMsgGr,
          RegistrationMsgEng: exhibition.RegistrationMsgEng,
          IsRegistered: exhibition.IsRegistered || 0,
          BarCode: exhibition.BarCode || '',
          ProfileID: exhibition.ProfileID || 0,
          HasTicket: exhibition.HasTicket,
        }));
      }
  
      return [];
    } catch (error) {
      console.error('Error fetching exhibitions:', error);
      return [];
    }
  };

export const fetchCompanies = async (exhibitionId) => {
  const requestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_LISTCOMPANY}/${exhibitionId}`,
    headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
  };
  console.log(requestConfig)

  try {
    const response = await axios(requestConfig);
    console.log('API Response:', response.data.results[5]);
    const companyData = response.data.results.map((company) => ({
      ActivityID: company.ActivityID,
      logoURL: company.logoURL || 'https://via.placeholder.com/150',
      CityGr: company.CityGr,
      CityEng: company.CityEng,
      EponCatGr: company.EponCatGr,
      EponCatEng: company.EponCatEng,
      CountryEng: company.CountryEng,
      CountryGr: company.CountryGr,
      StreetEng: company.StreetEng,
      ActivityIDNum: company.ActivityIDNum,
      ContactIDNum: company.ContactIDNum,
      PGR: company.PGR,
      PENG: company.PENG,
      eENG: company.eENG,
      eGR: company.eGR,
      WebSite: company.WebSite,
      RegionEng: company.RegionEng,
      RegionGr: company.RegionGr,
      StreetNum: company.StreetNum,
      BRANCH: company.BRANCH,
      StreetGr: company.StreetGr,
      MainActivityEponCatGr: company.MainActivityEponCatGr,
      MainActivityEponCatEng: company.MainActivityEponCatEng,
    }));
    console.log(companyData)
    return companyData;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw new Error('Failed to fetch companies');
  }
};

export const fetchExhibits = async (exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_LISTEXHIBITS}/${exhibitionId}`, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
    });

    const exhibitData = response.data.results.map((exhibit) => ({
      ExhibitCode: exhibit.ExhibitCode,
      DescrGr: exhibit.DescrGr,
      DescrEng: exhibit.DescrEng,
      PavCode: exhibit.PavCode,
      PavDescrGr: exhibit.PavDescrGr,
      PavDescrEng: exhibit.PavDescrEng,
      ExCode: exhibit.ExCode,
      ActivityID: exhibit.ActivityID,
      ExhibitCodeEnc: exhibit.ExhibitCodeEnc
    }));

    // Remove duplicates based on `DescrGr`
    const uniqueExhibits = Array.from(
      new Map(exhibitData.map((item) => [item.DescrGr, item])).values()
    );
   
    return uniqueExhibits;
  } catch (error) {
    console.error('Error fetching exhibits:', error);
    throw new Error('Failed to fetch exhibits');
  }
};

export const fetchPavilions = async (exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVILION}/${exhibitionId}`, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}` },
    });

    const PavilionData = response.data.results.map((Pavilion) => ({
      PavCode: Pavilion.PavCode,
      PavDescrGr: Pavilion.PavDescrGr,
      PavDescrEng: Pavilion.PavDescrEng,
    }));

    return PavilionData;
  } catch (error) {
    console.error('Error fetching pavilions:', error);
    throw new Error('Failed to fetch pavilions');
  }
};

export const fetchExhibitsByPavilion = async (pavilionId, exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVEXHBITIS}/${exhibitionId}/${pavilionId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
      },
    });
   console.log(response.data)
    // Check if the API returned an unexpected HTML response
    if (typeof response.data !== 'object' || response.headers['content-type']?.includes('text/html')) {
      console.error('Unexpected API response (Exhibits):', response.data);
      throw new Error('Invalid API response for exhibits.');
    }
    return response.data.results;
  } catch (error) {
    console.error('Error fetching exhibits:', error);
    throw new Error('Failed to fetch exhibits for the pavilion.');
  }
};

export const fetchExhibitorsByPavilion = async (pavilionId, exhibitionId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PAVEXHIBITOR}/${exhibitionId}/${pavilionId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_USETOKEN}`,
      },
    });
   console.log(response.data)
    // Check if the API returned an unexpected HTML response
    if (typeof response.data !== 'object' || response.headers['content-type']?.includes('text/html')) {
      console.error('Unexpected API response (Exhibitors):', response.data);
      throw new Error('Invalid API response for exhibitors.');
    }
    return response.data.results;
  } catch (error) {
    console.error('Error fetching exhibitors:', error);
    throw new Error('Failed to fetch exhibitors for the pavilion.');
  }
};




export const sendPostRequest = async (id,token) => {
    try {
      const url = `${process.env.REACT_APP_SCAN}`; 
      console.log(`Bearer ${token}`)
      const payload = {
        barcode: id,
      };
      console.log(id)
      const response = await axios.post(url, payload, {
        headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        },
      });
      console.log('res data',response.data)
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

  // Update the hasUserData function to work with exhibition-specific storage
export const hasUserData = (exhibitionId) => {
  if (!exhibitionId) return false;
  
  try {
    const userData = localStorage.getItem(`userData_${exhibitionId}`);
    return !!userData;
  } catch (error) {
    console.error('Error checking user data:', error);
    return false;
  }
};

// Update the fetchAndStoreUserData function if needed
export const fetchAndStoreUserData = async (username, password, exhibitionId) => {
  try {
    const requestBody = JSON.stringify({
      un: username,
      pwd: password,
    });
    console.log(requestBody)

    const response = await fetch('https://api.helexpo.gr/scan/api/login/e', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });
    
    const data = await response.json();
    
    if (response.ok && data.token) {
      return data;
    } else {
      throw new Error(data.message || 'Authentication failed');
    }
  } catch (error) {
    console.error('Error during authentication:', error);
    throw error;
  }
};

// Add a utility function to get all exhibitions the user is logged into
export const getLoggedInExhibitions = () => {
  const loggedInExhibitions = {};
  
  try {
    // Iterate through localStorage keys
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      
      // Check if this is a userData key for an exhibition
      if (key.startsWith('userData_')) {
        const exhibitionId = key.replace('userData_', '');
        loggedInExhibitions[exhibitionId] = true;
      }
    }
  } catch (error) {
    console.error('Error retrieving logged in exhibitions:', error);
  }
  
  return loggedInExhibitions;
};